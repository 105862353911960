import { useParams, useNavigate } from "react-router-dom";
import Header from "../common/Header";
import { resetPassword } from "../services/Services";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockKeyhole,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../common/Footer";

export default function ResetPassword() {
  let { token } = useParams();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at 8 char long")
      .matches(/[0-9]/, getCharacterValidationError("digit"))
      .matches(/[a-z]/, getCharacterValidationError("lowercase"))
      .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
      .matches(/^(?=.*[!@#$%^&*])/, getCharacterValidationError("special")),
    confirmPwd: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    delete data["confirmPwd"];
    data["token"] = token;
    resetPassword(data, signal)
      .then((res) => {
        if (res.message) {
          reset();
          navigate("/forward");
        } else if (res.error) {
          setMessage(res.error);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
      });
    //cleanup
    return () => controller.abort();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="container-fluid navbar-hero-div">
        <Header />
        <div className="container text-center px-5 py-5">
          <div className="row row-cols-2 align-items-center g-4 py-5">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center">
              <FontAwesomeIcon
                icon={faUnlockKeyhole}
                className="text-primary"
                style={{ width: "200px", height: "80px" }}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-start">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                  <h4 className="gifter-details-header2">Reset Password</h4>
                </div>
                {/*  password */}
                {/* <div
                  className={
                    "row form-floating " + (errors.password ? "" : "mb-3")
                  }
                >
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    className={
                      "form-control " + (errors.password ? "is-invalid" : "")
                    }
                    {...register("password")}
                  />
                  <label htmlFor="password">Password *</label>
                </div>
                {errors.password?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.password.message}
                    </span>
                  </div>
                )} */}

                {/*  confirm password */}
                {/* <div
                  className={
                    "row form-floating " + (errors.confirmPwd ? "" : "mb-3")
                  }
                >
                  <input
                    type="password"
                    id="confirmPwd"
                    placeholder="Confirm Password"
                    className={
                      "form-control " + (errors.confirmPwd ? "is-invalid" : "")
                    }
                    {...register("confirmPwd")}
                  />
                  <label htmlFor="confirmPwd">Confirm Password *</label>
                </div>
                {errors.confirmPwd?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.confirmPwd.message}
                    </span>
                  </div>
                )} */}

                {/*  password */}
                <div
                  className={"input-group " + (errors.password ? "" : "mb-3")}
                >
                  <div className=" row form-floating">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password1"
                      name="password"
                      placeholder="Password"
                      className={
                        "form-control " + (errors.password ? "is-invalid" : "")
                      }
                      {...register("password")}
                    />
                    <label htmlFor="password1">Password *</label>
                  </div>
                  <span
                    className="row input-group-text span-eye-pwd user-reg-eye-pwd"
                    id="password"
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                    />
                  </span>
                </div>
                {errors.password?.message && (
                  <div className="row align-items-start text-start mb-1">
                    <span className="span-errors" role="alert">
                      {errors.password.message}
                    </span>
                  </div>
                )}

                {/*  confirm password */}
                <div
                  className={"input-group " + (errors.confirmPwd ? "" : "mb-3")}
                >
                  <div className=" row form-floating">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password2"
                      name="confirmPwd"
                      placeholder="Confirm Password"
                      className={
                        "form-control " +
                        (errors.confirmPwd ? "is-invalid" : "")
                      }
                      {...register("confirmPwd")}
                    />
                    <label htmlFor="password2">Confirm Password *</label>
                  </div>
                  <span
                    className="row input-group-text span-eye-pwd user-reg-eye-pwd"
                    id="password2"
                  >
                    <FontAwesomeIcon
                      icon={passwordVisible ? faEyeSlash : faEye}
                      onClick={togglePasswordVisibility}
                    />
                  </span>
                </div>
                {errors.confirmPwd?.message && (
                  <div className="row align-items-start text-start mb-3">
                    <span className="span-errors" role="alert">
                      {errors.confirmPwd.message}
                    </span>
                  </div>
                )}

                <div className="row mb-2">
                  <div className="col-12">
                    <ul>
                      <li>Password must be minimum 8 characters in length</li>
                      <li>
                        Must contain Uppcase (A-Z), Lowercase (a-z), Numbers
                        (0-9) and Non-alphanumberic numbers (example: !,#, $, %)
                      </li>
                    </ul>
                  </div>
                </div>

                {/* submit */}
                <div className="row mb-2 text-center">
                  <div className="col-12 my-3">
                    <button
                      type="submit"
                      className="btn btn-light navbar-button"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
