import { useEffect, useState } from "react";
import { gifter_url, amazon_s3_logo } from "../../services/Services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faCircle } from "@fortawesome/free-solid-svg-icons";

export default function SearchRow({ sr, idx }) {
  const [num, setNum] = useState(0);

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    setNum(randomNumberInRange(1, 5));
  }, []);

  return (
    <div className="col-12 col-md-4 my-2">
      <a
        href={gifter_url + sr.id}
        className="col-md-6 align-items-start company-results-link my-2 py-3"
      >
        <div className="mx-2 shadow-lg border-2 bgc-white rounded">
          <div className="w-25 justify-content-start align-top d-inline-block">
            {sr.logo === null ? (
              <img
                src={"img/cards/search/gift-card-" + num + ".jpg"}
                className="card-img-top rounded company-results-logo img-fluid mx-2 my-2"
                style={{ border: "none" }}
                alt="logo"
              />
            ) : (
              <img
                src={amazon_s3_logo + "/" + sr.logo}
                className="card-img-top rounded company-results-logo img-fluid mx-2 my-2"
                style={{ border: "none" }}
                alt="logo"
              />
            )}
          </div>
          <div className="w-50 justify-content-start d-inline-block">
            <div className="mx-4 my-1">
              <p className="text-start">
                <span
                  className="fw-bold search-company-name"
                  style={{ textOverflow: "clip" }}
                >
                  {sr.company_name}
                  <br />
                </span>
                {sr.address1}
                <br />
                {sr.address2 ? sr.address2 + "<br/>" : ""}
                {sr.city}, <br /> {sr.state} - {sr.zip}
              </p>
            </div>
          </div>
          <div className="w-25 justify-content-start d-inline-block clearfix">
            <div className="mx-4 my-4 float-end">
              <span className="fa-layers fa-2x">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: "#4548A2" }}
                  transform={"grow-6"}
                />
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  transform={"grow-2"}
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    color: "white",
                  }}
                  // onClick={handleEdit}
                />
              </span>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
