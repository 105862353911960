import SearchRow from "./SearchRow";
import { useState } from "react";
import { getCompaniesList } from "../../services/Services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { statesList } from "../../services/Data";
import Support from "../../Support";
import Footer from "../../common/Footer";
import NavBar from "../../common/NavBar";
import { useLocation } from "react-router-dom";

export default function Search() {
  const location = useLocation();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [data, setData] = useState(null);
  const [selStateStyle, setSelStateStyle] = useState("");

  let [loading, setLoading] = useState();

  const spinner = (
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  const formSchema = Yup.object().shape({
    company_name: Yup.string()
      .test(
        "oneOfRequired",
        "One of Business Name, City, State or Zip Code must be entered",
        (item, testContext) => {
          return (
            testContext.parent.company_name ||
            testContext.parent.city ||
            testContext.parent.state
          );
        }
      )
      .matches(/.{4,}/, {
        excludeEmptyString: true,
        message: "Please enter atleast 4 characters to search in Business Name",
      }),
    city: Yup.string().test(
      "oneOfRequired",
      "One of Business Name, City, State or Zip Code must be entered",
      (item, testContext) => {
        return (
          testContext.parent.company_name ||
          testContext.parent.city ||
          testContext.parent.state
        );
      }
    ),
    state: Yup.string().test(
      "oneOfRequired",
      "One of Business Name, City, State or Zip Code must be entered",
      (item, testContext) => {
        return (
          testContext.parent.company_name ||
          testContext.parent.city ||
          testContext.parent.state
        );
      }
    ),
  });

  const formOptions = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data, e) => {
    const controller = new AbortController();
    const signal = controller.signal;

    setLoading(true);
    const params = {
      company_name: data.company_name,
      city: data.city,
      state: data.state,
    };
    getCompaniesList(params, signal)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setError(true);
        setLoading(false);
      });
    //cleanup function
    return () => {
      controller.abort();
    };
  };

  return (
    <>
      {location.pathname !== "/" ? <NavBar /> : ""}
      <div
        className={
          "container-fluid search-faq-div py-5" +
          (location.pathname !== "/" ? " pt-5 div-bank-info" : "")
        }
      >
        <div className="container text-center px-5 py-5">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="mx-auto p-2">
                <p className="fs-6 fw-bold pb-1 search-small">
                  Join our community of supporters.
                </p>
                <p className="fs-2 fw-bolder  pb-0 search-big">Gift Card</p>
                <p className="fs-6 fw-normal pb-0">
                  Send a gift card to friends and family or buy it now for your
                  future use.
                </p>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="row g-3 form-floating px-5"
          >
            <div className="row align-items-center">
              <div className="col-12 col-md-4 px-0">
                {/* company name */}
                <div className="form-floating g-0 my-3">
                  <input
                    type="text"
                    id="company_name"
                    placeholder="Business Name"
                    className={
                      "form-control no-curve-border-right " +
                      (errors.company_name ? "is-invalid" : "")
                    }
                    {...register("company_name")}
                  />
                  <label htmlFor="company_name">Business Name *</label>
                </div>
              </div>
              <div className="col-12 col-md-3 px-0">
                <div className="form-floating g-0 my-3">
                  <input
                    type="text"
                    id="city"
                    placeholder="City *"
                    className={
                      "form-control no-curve-border " +
                      (errors.city ? "is-invalid" : "")
                    }
                    {...register("city")}
                  />
                  <label htmlFor="city">City *</label>
                </div>
              </div>
              <div className="col-12 col-md-3 px-0">
                <div className="form-floating g-0 my-3">
                  <select
                    id="state"
                    placeholder="State *"
                    className={
                      "form-select no-curve-border-left " +
                      (errors.state ? "is-invalid " : " ")
                    }
                    aria-label="State"
                    {...register("state", {
                      onChange: (e) => {
                        setSelStateStyle("after-select");
                      },
                    })}
                  >
                    <option value=""></option>
                    {statesList.map((x, index) => (
                      <option key={index} value={x.value}>
                        {x.name}
                      </option>
                    ))}
                  </select>

                  <label
                    htmlFor="state"
                    className={"spl-label-select " + selStateStyle}
                  >
                    State *
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-2 px-0 d-grid">
                <button
                  type="submit"
                  className="g-0 my-3 btn btn-light navbar-button"
                >
                  search
                </button>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-12">
                {(errors.company_name?.message ||
                  errors.city?.message ||
                  errors.state?.message) && (
                  <div className="row align-items-center text-center my-3">
                    <span className="span-errors" role="alert">
                      *{" "}
                      {errors.company_name?.message ||
                        errors.city?.message ||
                        errors.state?.message}
                    </span>
                  </div>
                )}
                {error && (
                  <div
                    className="alert alert-danger row align-items-center text-center my-5"
                    role="alert"
                  >
                    <p className="mb-0">{message}</p>
                  </div>
                )}
                {(data === undefined || data?.length === 0) && (
                  <div
                    className="alert alert-primary row align-items-center text-center my-5"
                    role="alert"
                  >
                    <p className="mb-0">
                      Nothing found for the given criteria.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        {loading && spinner}
        {data && (
          <div className="container">
            <div className="d-flex align-content-center flex-wrap px-5">
              {data &&
                data.map((sr, index) => (
                  <SearchRow key={sr.id} sr={sr} idx={index} />
                ))}
            </div>
          </div>
        )}
        {location.pathname === "/" ? (
          <section id="support">
            <Support />
          </section>
        ) : (
          ""
        )}
      </div>

      {location.pathname !== "/" ? <Footer /> : ""}
    </>
  );
}
